<template>
    <app-wrapper>
        <template v-slot:child>
            <CreateEntity :closeModal="() => toggleCreateEntity()" @on-created="entityCreated" />
            <div class="w-90 center">
                <div class="flex flex-wrap justify-between items-center pv3">
                    <div>
                        <p class="pb2 f3 b">
                            Purchase Order
                            <strong
                                ><font-awesome-icon
                                    icon="fa-solid fa-question-circle fa-2xs"
                                    class="text-muted clickTooltipIcon"
                                    @click="toggleTooltip = true"
                                    style="cursor: pointer"
                            /></strong>
                        </p>
                        <div class="dashboard-inquiry-modal" v-if="toggleTooltip">
                             A purchase order (PO) is a document that a buyer sends to a seller to request the purchase of goods or
                            services. It includes information about the items being purchased, the quantity, the price, the
                            delivery date, and the payment terms. The PO is a legally binding contract between the buyer and the
                            seller.
                        </div>
                    </div>
                    <div class="scrollmenu flex">
                        <router-link
                            active-class="active"
                            :to="{ name: 'PurchaseOrderCreate', query: { id: $route.params.id } }"
                            v-if="(state.status === 'pending' || state.status === 'draft') && (role === 'owner' || role === 'admin' || rolePermissions?.includes(58))"
                        >
                            <button class="mr2 flex items-center" style="height: 45px">Edit</button>
                        </router-link>
                        <!-- <router-link :to="{ name: 'StockTransfers' }" class="btn">Back</router-link> -->
                        <button
                            style="background-color: white; border: 1px solid #132C8C; color: #132C8C;"
                            class="dropdown-togglex "
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            More <font-awesome-icon icon="fa-solid fa-caret-down" class="fa-2x" />
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" style=" z-index: 2000">
                            <li v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(57)" style="cursor: pointer" @click="duplicatePurchaseOrder($route.params.id)">Duplicate</li>
                            <li v-if="state.status === 'pending' && (role === 'owner' || role === 'admin' || rolePermissions?.includes(60))">
                                <a
                                    href="#"
                                    class="dropdown-itemx"
                                    @click="actOnPurchaseOrder($route.params.id, 'received')"
                                >
                                    Receive
                                </a>
                            </li>
                            <li v-if="state.status === 'pending'">
                                <a
                                    href="#"
                                    class="dropdown-itemx"
                                    @click="actOnPurchaseOrder($route.params.id, 'cancelled')"
                                >
                                    Cancel
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="flex justify-start">
                    <div class="w-100 w-30-l pr3">
                        <h4>Order Details</h4>
                        <p>
                            Adding details to this purchase order helps staff stay on the top of orders and easily identify the items
                        </p>
                    </div>
                    <div class="w-100 w-70-l">
                        <div class="flex flex-wrap justify-between">
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Choose Supplier/Enter Supplier Name</label>
                                <div class="flex flex-column"
                                    :style="`position: ${state.isRelative ? 'relative' : 'revert'}`"
                                    >
                                    <input
                                        autocomplete="off"
                                        class="box-borderx product-inputxx form-sign"
                                        style="padding: 10px"
                                        type="text"
                                        name="entity"
                                        placeholder="Search for supplier name"
                                        id="entity"
                                        disabled
                                        @input="element => handleEntitySearchOnChange(element.target.value)"
                                        @focusout="
                                            () => {
                                                showDropDownOnSelectEntity(false)
                                                handleIsRelative(false)
                                            }
                                        "
                                        @focusin="
                                            () => {
                                                showDropDownOnSelectEntity(true)
                                                handleIsRelative(true)
                                            }
                                        "
                                        v-model="state.supplier"
                                    />
                                    <template v-if="state.supplierDropDown && state.entity?.length">
                                        <div
                                            style="
                                            position: absolute;
                                            left: 0;
                                            top: 100%;
                                            height: auto;
                                            width: 100%;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            box-shadow: 0px 1px 4px 0px #2b59ff;
                                            z-index: 1000;
                                            "
                                            class="bg-white flex flex-column items-start"
                                        >
                                            <div
                                                class="mt2"
                                                style="width: 100%; max-height: 250px; overflow-y: scroll"
                                                v-if="EntitySearchedList.length"
                                            >
                                                <ul class="w-100" style="margin:0;padding:10px 0">
                                                    <li v-for="ent of EntitySearchedList" v-bind:key="ent._id"
                                                        class="pointer hover-bg-black-10 pa2 db"
                                                        style="width: 100%; margin:0;"
                                                        @click.prevent="handleEntityClickFromSearch(ent)"
                                                    >
                                                        <span v-if="ent?.lastName !== undefined">
                                                            {{ `${ent?.firstName} ${ent?.lastName}` || ent?.company }}
                                                        </span>

                                                        <span v-else>
                                                            {{ `${ent?.firstName}` || ent?.company }}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-else class="pa3">Not found</div>
                                            <div
                                                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(30)"
                                                class="flex items-center"
                                                style="width: 100%; gap: 0.5rem; overflow: hidden; text-overflow: ellipsis"
                                            >
                                                <button 
                                                    class="mr2x pa2"
                                                    style="width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
                                                    @click.prevent="toggleCreateEntity(true)"
                                                >
                                                    <font-awesome-icon icon="fa fa-plus-circle" /> Create a new customer
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Delivery Outlet</label>
                                <select
                                    class="form-sign"
                                    v-model="state.deliveryOutlet"
                                    name="deliveryOutlet"
                                    id="deliveryOutlet"
                                    style="padding: 10px"
                                    disabled
                                >
                                    <option value="">Select source business</option>
                                    <option v-for="business in state.businesses" :key="business._id" :value="business.org">
                                        {{ business.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-wrap justify-between">
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Supplier Invoice Number</label>
                                <input
                                    class="form-sign"
                                    v-model="state.reference"
                                    name="reference"
                                    id="reference"
                                    style="padding: 10px"
                                    disabled
                                />
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Order Number</label>
                                <input
                                    class="form-sign"
                                    v-model="state.number"
                                    name="reference"
                                    id="reference"
                                    style="padding: 10px"
                                    disabled
                                />
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Delivery date</label>
                                <input
                                    type="date"
                                    class="form-sign"
                                    v-model="state.date"
                                    name="date"
                                    id="date"
                                    style="padding: 10px"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="flex justify-start">
                    <div class="w-100 w-30-l pr3">
                        <h4>Products</h4>
                        <p>Choose products to add to this purchase order</p>
                    </div>
                    <div class="w-100 w-70-l">
                        <div v-if="state.products.length" class="pb3">
                            <table class="table table-bordered table-striped" cellspacing="0">
                                <caption></caption>
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Current stock</th>
                                        <th>Quantity</th>
                                        <th>Cost price ({{ $store.state.Settings?.currency || 'NGN' }})</th>
                                        <th>Total price ({{ $store.state.Settings?.currency || 'NGN' }})</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(product, index) in state.products" :key="product.id">
                                        <td class="vertical-align-middle">{{ product.name }}</td>
                                        <td class="vertical-align-middle">{{ product.current_stock }}</td>
                                        <td class="vertical-align-middle">
                                            <input 
                                                type="number" 
                                                class="product-input-2" 
                                                v-model="product.quantity"
                                                :max="product.current_stock"
                                                @input="(e) => watchTransferQtyChange(e.target.value, product.current_stock, index)"
                                                disabled
                                            />
                                        </td>
                                        <td class="vertical-align-middle">
                                            <input 
                                                type="number" 
                                                class="product-input-2"  
                                                v-model="product.price"
                                                disabled
                                            />
                                        </td>
                                        <td class="vertical-align-middle">{{ formatAmount(product.quantity * product.price, $store.state.Settings?.currency || 'NGN') }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                                <div v-if="state?.staffName" className="flex justify-between pb4">
                                    <div>Created by: <span>{{ state.staffName }}</span></div>
                                    <div>Date: {{ formatDate(state.createdAt) }}</div>
                                </div>
                                <div v-if="state?.actorName" className="flex justify-between">
                                    <div>{{state.status === "received" ? 'Received'  : 'Rejected'}} by: <span>{{ state.actorName }}</span></div>
                                    <div>Date: {{ formatDate(state.actedAt) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import AppWrapper from '@/layout/AppWrapper'
import Spinner from '@/components/Spinner'
import CreateEntity from '@/components/CreateEntity'
import ShortUniqueId from 'short-unique-id'
import moment from 'moment-timezone'
import { formatAmount, formatDate } from '@/utils/lib'

export default {
    name: 'PurchaseOrderView',
    components: { AppWrapper, Spinner, CreateEntity },

    setup() {
        let timeOut = null
        const toggleTooltip = ref(false)
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const userData = computed(() => store?.state?.Auth?.userData)
        const productEntrySearchList = computed(() => store.state.Inventory?.productEntrySearchList)
        const incomingSupplierId = computed(() => store.state.Entity?.incomingEntityId)
        const EntitySearchedList = computed(() => store.state.Entity?.entityListFromSearch)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)

        const initialState = () => ({
            isProcessing: false,
            supplier: '',
            supplierData: {
                id: '',
                name: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                vat_number: '',
                tax_number: '',
                website: ''
            },
            supplierId: '',
            supplierName: '',
            deliveryOutlet: '',
            reference: new ShortUniqueId({ length: 6 }).randomUUID(),
            number: '',
            date: moment().format('YYYY-MM-DD'),
            products: [],
            businesses: [],
            status: 'draft',
            staffName: '',
            actorName: '',
            createdAt: '',
            actedAt: '',
            formMode: 'create',
            supplierDropDown: false,
            isRelative: false,
        })

        const state = reactive(initialState())

        const closeTooltip = () => {
            toggleTooltip.value = false
        }

        const handleEntitySearchOnChange = query => {
            state.entity = query

            clearTimeout(timeOut)

            if (query.trim().length) {
                timeOut = setTimeout(() => {
                    store.dispatch('Entity/getEntityEntryBySearch', { query })
                }, 300)
            }
        }

        const showDropDownOnSelectEntity = (dropDownState = false) => {
            if (!dropDownState) {
                return setTimeout(() => {
                    state.supplierDropDown = dropDownState
                }, 300)
            }

            state.supplierDropDown = dropDownState
        }

        const handleEntityClickFromSearch = value => {
            // console.log(value, 'value')

            if (value?.lastName || value?.lastName === undefined) {
                state.supplier = `${value?.firstName} ${value?.lastName || ''}` || value?.company
            } else {
                state.supplier = `${value?.firstName}` || value?.company
            }

            state.supplierName = value._id

            // update invoice customer
            if (value._id) {
                state.supplierData.id = value._id
                state.supplierData.name = value?.lastName ? `${value.firstName} ${value?.lastName}` : value.firstName
                state.supplierData.address = value.address1
                state.supplierData.city = value.city
                state.supplierData.state = value.state
                state.supplierData.zip = value.zip
                state.supplierData.country = value.country
                state.supplierData.phone = value.phone
                state.supplierData.email = value.email

                state.IsCustomerSelected = true
            }

            store.dispatch('Entity/setEntityIdFromExistingEntities', value._id).then(resp => {
                if (resp) onChangeSupplierId()
            })
            showDropDownOnSelectEntity(false)
        }

        const onChangeSupplierId = () => {
            state.supplierId = incomingSupplierId.value
        }

        const entityCreated = entity => {
            handleEntityClickFromSearch(entity)
        }

        const toggleCreateEntity = payload => {
            store.dispatch('Entity/toggleCreateEntity', payload)
            if (payload && state.entity) store.dispatch('Entity/setEntityName', state.entity)

            const body = document.querySelector('body')
            if (payload) {
                body.style.overflow = 'hidden'
            } else {
                body.style.overflow = 'auto'
            }
        }

        const handleIsRelative = (value = false) => {
            setTimeout(() => {
                state.isRelative = value
            }, 300)
        }

        watch(
            () => incomingSupplierId.value,
            (currValue, prevValue) => {
                if (currValue !== prevValue) {
                    state.supplierId = incomingSupplierId.value
                }
            }
        )

        const onSearchProduct = (search, loading) => {
            if (search.length) {
                loading(true)
                store.dispatch('Production/searchProduction', { query: search, type: 'ALL', business: state.deliveryOutlet }).then(data => {
                    state.options = data.products
                    loading(false)
                })
            }
        }

        const selectProduct = (item) => {
            state.products.push({
                name: item.name,
                product: item._id,
                price: item?.buyingPrice || 0,
                current_stock: item?.qty_in_stock || 0,
                quantity: 1,
                instock: item?.instock || 0,
                sku: item?.sku || '',
                id: item._id
            })
            state.options = []
        }

        const deselectProduct = (product, item) => {
            
        }

        const resetState = () => {
            state.reference = new ShortUniqueId({ length: 6 }).randomUUID()
            state.date = moment().format('YYYY-MM-DD')
            state.products = []
            state.options = []
        }

        const watchTransferQtyChange = (value, max, index) => {
            if (value > max) {
                state.products[index].quantity = max
            }
        }

        const removeProduct = (index) => {
            const { products } = state
            const productToDelete = products.splice(index, 1)
            state.products = products.filter(element => element !== productToDelete[0])
        }

        const actOnPurchaseOrder = (id, status) => {
            const title = status === 'received' ? 'Receive' : 'Reject'
            const content = status === 'received' ? 'Receive' : 'reject'
            Swal.fire({
                title: `${title} Purchase Order`,
                text: `Are you sure you want to ${content} this purchase order?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Yes, ${title}!`,
                denyButtonText: `No, Cancel!`
            }).then(result => {
                if (result.isConfirmed) {
                    store.dispatch('Inventory/actOnPurchaseOrder', { id, status })
                        .finally(() => getPurchaseOrders())
                }
            })
        }

        const duplicatePurchaseOrder = async (id) => {
            Swal.fire({
                    title: `Confirm Action`,
                    text: `Are you sure you want to duplicate this purchase order?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Yes, Continue!',
                    denyButtonText: `No, Cancel!`
                }).then(async result => {
                    if (result.isConfirmed) {
                        await store.dispatch('Inventory/duplicatePurchaseOrder', { id }).then( resp => {
                                if (resp.status) {
                                    router.push({
                                        name: 'PurchaseOrderCreate',
                                        query: { id: resp.data }
                                    })
                                }
                            })
                                    
                    }
                })
        }

        const getPurchaseOrders = async () => {
            const query = route.query.limit && route.query.page ? { limit: route.query.limit, page: route.query.page } : null
            state.startDate1 = formatDateFilter(state.startDate1)
            await store.dispatch('Inventory/getPurchaseOrders', { query })
        }

        const handlePurchaseOrder = (status = 'draft') => {
            Swal.fire({
                    title: status === 'draft' ? `Confirm Save as Draft` : `Confirm Purchase Order`,
                    text: status === 'draft' ? `Are you sure you want to save this purchase order as draft?` : `Are you sure you want to submit this purchase order?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Yes, Continue!',
                    denyButtonText: `No, Cancel!`
                }).then(result => {
                    if (result.isConfirmed) {
                        state.isProcessing = true
                        const deliveryOutlet = state.businesses.filter(business => business.org === state.deliveryOutlet)[0]
                        const data = {
                            supplier: state.supplierData,
                            deliveryOutlet: { id: deliveryOutlet.org, name: deliveryOutlet.name },
                            number: state.number,
                            reference: state.reference,
                            date: state.date,
                            products: state.products,
                            status: status,
                        }

                        data.totalItems = state.products.reduce((prevVal, currValue) => {
                            prevVal += parseFloat(currValue.quantity)
                            return prevVal
                        }, 0)

                        data.totalValue  = state.products.reduce((prevVal, currValue) => {
                            prevVal += parseFloat(currValue.price) * parseFloat(currValue.quantity)
                            return prevVal
                        }, 0)

                        if (state.formMode === 'create') {
                            store.dispatch(`Inventory/createPurchaseOrder`, data)
                                .finally(() => {
                                    resetState()
                                    state.isProcessing = false
                                    router.push({ name: 'PurchaseOrders' })
                                })
                        } else {
                            const { id } = route.query
                            store.dispatch(`Inventory/updatePurchaseOrder`, { id, data })
                                .finally(() => state.isProcessing = false)
                        }
                                    
                    }
                })
        }

        const getPurchaseOrder = (id) => {
            store.dispatch('Inventory/getPurchaseOrder', { id }).then(resp => {
                const { status, data } = resp
                if (status) {
                    state.supplierName = data?.supplier?.name || ''
                    state.supplier = data?.supplier?.name || ''
                    state.supplierId = data?.supplier?.id || ''
                    state.supplierData = data?.supplier
                    state.deliveryOutlet = data?.deliveryOutlet?.id || ''
                    state.reference = data?.reference
                    state.number = data?.number
                    state.date = data?.date
                    state.products = data?.products
                    state.staffName = data?.staffName || ''
                    state.actorName = data?.actorName || ''
                    state.createdAt = data?.createdAt || ''
                    state.actedAt = data?.actedAt || ''
                    state.status = data?.status
                    state.formMode = 'edit'
                }
            })
        }

        onMounted(() => {
            store.dispatch('Settings/getBusinesses', true).then(resp => {
                if (resp.status) {
                    state.businesses = resp.data
                }
            }).finally(() => {
                getPurchaseOrder(route.params.id)
            })

            window.addEventListener('click', event => {
                const tooltipIcon = document.querySelector('.clickTooltipIcon')

                if (
                    !(event.target.closest('.clickTooltipIcon') || event.target === tooltipIcon) &&
                    toggleTooltip.value === true
                ) {
                    closeTooltip()
                }
            })
        })

        return {
            state,
            userData,
            role,
            rolePermissions,
            toggleTooltip,
            onSearchProduct,
            selectProduct,
            deselectProduct,
            handlePurchaseOrder,
            productEntrySearchList,
            formatAmount,
            formatDate,
            watchTransferQtyChange,
            removeProduct,
            handleEntitySearchOnChange,
            showDropDownOnSelectEntity,
            handleEntityClickFromSearch,
            EntitySearchedList,
            entityCreated,
            toggleCreateEntity,
            handleIsRelative,
            actOnPurchaseOrder,
            duplicatePurchaseOrder,
        }
    }
}
</script>

<style scoped></style>
